import React from 'react'

const Footer = () => {
  return (
    <>
        <div className="bg-gray-900 text-white text-center p-4  ">
            <span className='text-right'>Netflix 2022 © | Designed by <a href="https://jassy.in" target="_blank" rel="noreferrer">Jassy</a></span>
        </div>
        
        
        
    </>
  )
}

export default Footer